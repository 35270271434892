<template>
  <v-container class="fill-height" fluid>
    <div class="home" style="width:100%">
        <v-card v-for="group, groupidx in tasks" v-bind:key="groupidx" class="mb-4">
            <v-card-title>{{ group.title }}</v-card-title>
            <v-list>
                <template v-for="task in group.tasks">
                    <v-list-item two-line v-bind:key="task.id" :class="{ priority_item: task.extraPrio }">
                        <v-list-item-avatar>
                            <v-avatar style="height: 40px; min-width: 40px; width: 40px;">
                                <img src="img/jira.png" v-if="task.type === 'Jira'">
                                <img src="img/logo.svg" v-else-if="task.type === 'Connection'">
                                <div v-else>{{ task.type }}</div>
                            </v-avatar> 
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title>{{ task.title }}</v-list-item-title>
                            <v-list-item-subtitle>
                                <div v-if="task.subtitle">{{task.subtitle}}</div>
                                <div v-if="task.tags">Tags: {{task.tags}}</div>
                                <div v-if="task.duedate">Due: {{task.duedate}}</div>
                            </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-btn :href="task.link" target="_blank" v-if="task.link">open</v-btn>
                            <v-btn @click="setSeen(task)" v-if="task.type !== 'Connection' && group.title === 'new'">seen</v-btn>
                            <v-btn @click="togglePrio(task)" v-if="task.type !== 'Connection'">prio</v-btn>
                            <v-btn @click="hideUntilChanged(task)" v-if="task.type !== 'Connection'">hide</v-btn>
                            <v-btn @click="connectionfixed(task)" v-if="task.type === 'Connection'">FIXED</v-btn>
                        </v-list-item-action>
                    </v-list-item>
                    <v-divider v-bind:key="`${task.id}_divider`"></v-divider>
                </template>
            </v-list>
        </v-card>
    </div>
  </v-container>
</template>

<script>
import BackendService from '/service/BackendService';

  export default {
    name: 'TaskList',

    data: () => ({
        tasks: [],
    }),

    methods: {
        setSeen(task) {
            BackendService.seen({id: task.id, type: task.type}).then((data) => {
                this.resetTasks(data.data);
            });
        },
        togglePrio(task) {
            BackendService.togglePrio({id: task.id, type: task.type}).then((data) => {
                this.resetTasks(data.data);
            });
        },
        hideUntilChanged(task) {
            BackendService.hideUntilChanged({id: task.id, type: task.type}).then((data) => {
                this.resetTasks(data.data);
            });
        },
        connectionfixed(task) {
            BackendService.connectionfixed({id: task.id}).then((data) => {
                this.resetTasks(data.data);
            });
        },
        loadTasks() {
            BackendService.loadTasks().then((data) => {
                this.resetTasks(data.data);
            })
        },
        resetTasks(newTasks) {
            while (this.tasks.length > 0) {
                this.tasks.pop();
            }
            newTasks.forEach((task) => {
                this.tasks.push(task);
            });
        }
    },

    created() {
        if (document.load_tasks !== undefined) {
          window.clearInterval(document.load_tasks);
        }
        document.load_tasks = window.setInterval(this.loadTasks, 10000);
        this.loadTasks();
    },
  }
</script>
<style scoped>
.priority_item {
    color: #e91e63 !important;
}

.priority_item::before {
    background-color: currentColor;
    bottom: 0;
    content: "";
    left: 0;
    opacity: 0.1;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
}
</style>
<style>
</style>