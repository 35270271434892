<template>
  <v-container class="fill-height">
    <div class="pa-2 pa-md-4 flex-grow-1 align-center justify-center d-flex flex-column">
      <div class="layout-content ma-auto login-wrapper">
        <v-card class="text-center pa-1">
        <img data-v-083731e0="" src="img/logo.svg" class="logo">
        <v-card-title class="display-1 justify-center mb-2">TOO MANY TASKS</v-card-title>
        <v-card-subtitle>Please sign in</v-card-subtitle>
        <v-card-text>
          <v-text-field label="Username" outlined v-model="login"></v-text-field>
          <v-text-field label="Password" outlined type="password" v-model="password"></v-text-field>
          <v-btn color="primary" :loading="loading" @click="doLogin">Sign in</v-btn>
        </v-card-text>
      </v-card>
      </div>
    </div>
  </v-container>
</template>

<script>
import BackendService from '/service/BackendService';

  export default {
    name: 'LoginComponent',

    data: () => ({
      loading: false,
      login: '',
      password: '',
    }),

    methods: {
      doLogin() {
        this.loading = true;
        BackendService.login({ login: this.login, password: this.password })
          .then((data) => {
            if (data.data.success) {
              if (data.data.loggedin !== undefined) {
                this.$store.commit("updateLoginState", data.data.loggedin);
              }
            }
          }).finally(() => {
            this.loading = false;
          })
      },
    },
  }
</script>
<style scoped>
.logo {
  max-width: 100px;
}
.login-wrapper{
  width:480px;
  max-width:100%;
}
</style>
