import axios from 'axios';

const apiClient = axios.create({
    baseURL: '/api/',
    withCredentials: false, // This is the default
    timeout: 2000,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
});

export default {
    getState: function() {
        return apiClient.get("/state")
    },
    heartbeat: function() {
        return apiClient.get("/heartbeat")
    },
    login: function(data) {
        return apiClient.post("/login", data)
    },
    logout: function() {
        return apiClient.get("/logout")
    },
    loadTasks: function() {
        return apiClient.get("/tasks")
    },
    seen: function(data) {
        return apiClient.post("/seen", data)
    },
    togglePrio: function(data) {
        return apiClient.post("/toggleprio", data)
    },
    connectionfixed: function(data) {
        return apiClient.post("/resolveconnectiontask", data)
    },
    hideUntilChanged: function(data) {
        return apiClient.post("/hideuntilchanged", data)
    }
};