<template>
  <v-app dark>
    <div v-if="$store.state.loggedin">
      <v-toolbar>
        <v-dialog
          transition="dialog-bottom-transition"
          max-width="600"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
            v-bind="attrs"
            v-on="on"
            >NEW TASK</v-btn>
          </template>
          <template v-slot:default="dialog">
            <v-card>
              <v-toolbar
                color="primary"
                dark
              >New Task</v-toolbar>
              <v-card-text>
                TODO! TODO! TODO! TODO! TODO! TODO! TODO! 
                <v-container>
                  <v-row>
                    <v-col>
                      <v-text-field label="title" outlined />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field label="text" outlined />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6">
                      <v-menu
                        ref="menu"
                        v-model="menu"
                        :close-on-content-click="false"
                        :return-value.sync="date"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="date"
                            label="due"
                            prepend-icon="mdi-calendar"
                            readonly
                            clearable
                            outlined
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="date"
                          no-title
                          scrollable
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                            text
                            color="primary"
                            @click="menu = false"
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="$refs.menu.save(date)"
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="6">
                      <v-select
                        :items="priorities"
                        label="priority"
                        outlined
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions class="justify-end">
                <v-btn
                  @click="dialog.value = false"
                >Never mind...</v-btn>
                <v-btn
                  color="primary"
                  @click="dialog.value = false"
                >Let's do this!</v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </v-dialog>
        
        <v-spacer></v-spacer>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon
            v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-account</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
             @click="logout"
            >
              <v-list-item-title>Logout</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

      </v-toolbar>
      <v-main>
        <TaskList></TaskList>
      </v-main>
    </div>
    <LoginComponent v-else />
  </v-app>
</template>

<script>
import BackendService from '../service/BackendService';
import LoginComponent from './components/LoginComponent.vue';
import TaskList from './components/TaskList.vue';


export default {
  name: 'App',

  components: {
    LoginComponent,
    TaskList
  },

  data: () => ({
    //
    priorities: [
    {value: 'veryhigh', text: 'very high'},
    {value: 'high', text: 'high'},
    {value: 'normal', text: 'normal'},
    {value: 'low', text: 'low'},
    {value: 'very low', text: 'very low'},
    ],
  }),

  created() {
    if (document.ve_heartbeat !== undefined) {
      window.clearInterval(document.ve_heartbeat);
    }
    document.ve_heartbeat = window.setInterval(this.heartbeat, 3000);
    this.heartbeat();
  },
  methods: {
    heartbeat() {
      BackendService.heartbeat().then((e) => {
          if (e.data.loggedin !== undefined) {
            this.$store.commit("updateLoginState", e.data.loggedin);
          }
        })
      },
      logout() {
        BackendService.logout().then((data) => {
          if (data.data.success) {
              if (data.data.loggedin !== undefined) {
                this.$store.commit("updateLoginState", data.data.loggedin);
              }
            }
        });
      }
  },
};
</script>
<style>
@import url(https://fonts.googleapis.com/css2?family=Bungee&display=swap);
.v-application .display-1{
  font-family:Bungee,cursive!important;
}

#app{
  font-family:Avenir,Helvetica,Arial,sans-serif;
  -webkit-font-smoothing:antialiased;
  -moz-osx-font-smoothing:grayscale;
  color:#2c3e50
}

#nav{padding:30px}
#nav a{font-weight:700;color:#2c3e50}
#nav a.router-link-exact-active{color:#42b983}

</style>