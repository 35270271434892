import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    gotstate: false,
    loggedin: false
},
  getters: {
  },
  mutations: {
    upadteState: function(state, payload) {
      state.gotstate = true,
      state.loggedin = payload.loggedin
    },
    updateLoginState: function(state, payload) {
      state.loggedin = payload
    }
  },
  actions: {
  },
  modules: {
  }
})
